*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Urbanist", sans-serif;
  user-select: none;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  width: 90%;
  margin: 0 auto;
}

.highlight {
  font-weight: 700;
}

/* HEADER */
nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 5%;
  height: 75px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  z-index: 999;
}

.left-nav {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.left-nav .logo-image {
  width: 34px;
  height: 34px;
}

.left-nav .logo-image img {
  width: 100%;
  height: 100%;
}

.left-nav .logo-text {
  font-weight: 700;
  font-size: 1.3rem;
  color: #202020;
}

.right-nav {
  display: flex;
  align-items: center;
}

.right-nav ul {
  display: none;
}

.burger-menu {
  display: block;
}

.right-nav ul li {
  margin-left: 2em;
  font-size: 1.1rem;
}

.right-nav ul li a {
  font-weight: 300;
  color: #202020;
}

.right-nav .burger-menu {
  width: 26px;
}
.right-nav .burger-menu:hover {
  cursor: pointer;
}

.burger-menu-window {
  background-color: #fff;
  position: absolute;
  top: 80px;
  right: 5%;
  height: auto;
  width: auto;
  border-radius: 8px;
  font-weight: 700;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@keyframes fade-in {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    right: 0;
    opacity: 1;
  }

  100% {
    right: -100%;
    opacity: 0;
  }
}

.burger-menu-window ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 1em 2em 2em;
}

.burger-menu-window ul li {
  text-align: right;
  font-size: 1.3rem;
  font-weight: 300;
  width: 100%;
  background-color: #202020;
  color: #fff;
  padding: 0.3em 1em;
  text-align: center;
  border-radius: 3px;
}

.banner {
  background-image: url("../img/onde.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #000;
  display: flex;
  align-items: center;
  margin-top: 75px;
  height: calc(100vh - 75px);
}

.date {
  font-size: 1rem;
}

.banner .main-title h1 {
  font-size: 1.8rem;
  margin: 0.3em 0;
}

.banner .main-subtitle p {
  line-height: 1.5;
  font-size: 1.2rem;
}
/* END HEADER */

/* MAIN */
main .container {
  max-width: 1000px;
  color: #202020;
  margin-bottom: 3em;
}

/* TV SERIES */
#series {
  padding-top: 3em;
}

div.currently-subtitle,
div.form-subtitle {
  margin-top: 0.3em;
  font-size: 1.1rem;
  line-height: 1.5;
}
div.currently-subtitle {
  font-weight: 300;
}
.posts-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.posts-container .post-image {
  height: 180px;
  width: 100%;
  border-radius: 3px;
}

.posts-container .post-image img {
  border-radius: 3px;
}

.post {
  margin: 1.5em 0;
}

.post .date {
  padding: 0.8em 0 0.6em;
  color: #202020;
}

.post-title h2 {
  font-size: 1.3rem;
  color: #202020;
}

.post-description {
  padding: 0.6em 0;
  color: #202020;
}

.post-description p {
  line-height: 1.5;
  font-size: 1.1rem;
}

.show-more-posts {
  text-align: center;
  margin-bottom: 3em;
}
.show-more-posts button {
  padding: 1em 2em;
  border: none;
  background-color: #202020;
  color: #fff;
  border-radius: 3px;
  font-weight: 700;
  font-size: 0.9rem;
  transition: 0.3s;
}
.show-more-posts button:hover {
  cursor: pointer;
}

/* END TV SERIES */

/* MUSIC */
/* Spotify theme color black #121212 */
#music-playlist {
  background-color: #121212;
  padding: 3em 0 1em;
}
#music-playlist .currently-title,
#music-playlist .currently-subtitle {
  color: #fff;
}
.music-container {
  margin: 1em 0;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.song {
  margin: 0.8em 0;
  display: flex;
  align-items: center;
  gap: 1em;
}
.song .song-number {
  font-weight: 700;
}
.song .song-image {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 3px;
}
.song .song-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.song .song-info {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  align-self: flex-start;
}
.song-info .song-name {
  font-weight: 700;
  font-size: 1.1rem;
}
.song-info .song-artist {
  font-size: 1rem;
}
/* END MUSIC */

/* NBA */
#nba-highlights {
  padding: 3em 0 0;
}
.single-highlight {
  margin-bottom: 2em;
}
.highlight-title {
  font-weight: 300;
  margin: 2em 0 0.3em;
  line-height: 1.5;
  font-size: 1.1rem;
  color: #202020;
}
.highlight-video {
  width: 100%;
  height: 240px;
  border-radius: 3px;
}
.highlight-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.highlights {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}
/* END NBA */

/* ABOUT ME */
#about-me {
  padding: 3em 0 1em;
  background-color: #202020;
}
.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 2em;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#about-me .description p {
  line-height: 1.5;
  font-size: 1.1rem;
  color: #fff;
}
.second-paragraph {
  margin: 1em 0;
}
.cat-typing {
  border-radius: 5px;
  max-width: 400px;
  max-height: 400px;
}
.cat-typing img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* SOCIAL */
.socials {
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 2em 0;
}
.social {
  width: 40px;
  height: 40px;
}
.social a:hover {
  cursor: pointer;
}
.social img {
  width: 100%;
  height: 100%;
}
/* END SOCIAL */
/* END ABOUT ME */

/* CONTACT FORM */
.contact-form {
  padding: 3em 0 0;
}

form {
  margin: 2em 0 1em;
}
.form-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}
.inputs input,
textarea,
button#btn-submit {
  font-family: inherit;
  padding: 0.8em 1em;
  border: 1px solid lightgray;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
}
.textarea,
textarea {
  width: 100%;
}
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  max-height: 80px;
  opacity: 0;
  margin-bottom: 0.5em;
  text-align: center;
}
button#btn-submit {
  width: auto;
  margin: 0 auto;
  background-color: #202020;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 0.8em 2em;
}
button#btn-submit:hover {
  cursor: pointer;
}
/* END CONTACT FORM */

/* END MAIN */

/* FOOTER */
footer {
  background: #202020;
  color: #fff;
  text-align: center;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer .footer-title {
  font-weight: 400;
  margin-bottom: 0.3em;
  font-size: 1rem;
}
footer .footer-copyright {
  font-size: 0.85rem;
}

/* END FOOTER */

/* BACK TO TOP */
.back-to-top {
  width: 42px;
  height: 42px;
  position: fixed;
  right: 1em;
  bottom: 1em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 50%;
  display: none;
}
.back-to-top:hover {
  cursor: pointer;
}

.back-to-top img {
  width: 100%;
  height: 100%;
}

@keyframes arrow-in {
  0% {
    right: -100%;
  }

  100% {
    right: 1em;
  }
}

@keyframes arrow-out {
  0% {
    right: 1em;
  }

  100% {
    right: -100%;
  }
}
/* END BACK TO TOP */

/* ----------------------------------- RESPONSIVE ----------------------------------- */
/* --------------- BREAKPOINT 480 px --------------- */
@media (min-width: 480px) {
  nav {
    padding: 1.2em 5%;
  }

  .banner .main-title h1 {
    font-size: 2.2rem;
  }

  .banner .main-subtitle p {
    font-size: 1.2rem;
  }

  .banner-content .date {
    font-size: 0.9rem;
  }
}

/* --------------- BREAKPOINT 600 px --------------- */
@media (min-width: 600px) {
  .posts-container {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.25em;
  }

  .post {
    width: calc(100% / 2 - 0.5em);
  }
}

/* --------------- BREAKPOINT 768 px --------------- */
@media (min-width: 768px) {
  .left-nav {
    gap: 0.8em;
  }

  .left-nav .logo-image {
    width: 46px;
    height: 46px;
  }

  .left-nav .logo-text {
    font-size: 1.6rem;
  }

  .right-nav ul {
    display: flex;
  }

  .burger-menu {
    display: none;
  }

  .burger-menu-window {
    display: none;
  }

  .banner-content,
  .about-me-content {
    max-width: 900px;
  }

  .banner .main-subtitle p {
    font-size: 1.3rem;
  }

  .posts-container {
    gap: 0.5em;
  }
  .highlights {
    gap: 1em;
  }
  .post {
    width: calc(100% / 3 - 1em);
  }
  .highlights {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .single-highlight {
    width: calc(100% / 2 - 0.5em);
  }

  .post-title h2 {
    font-size: 1.3rem;
  }

  .inputs {
    flex-direction: row;
  }

  .inputs input {
    width: calc(45% - 0.5em);
  }

  textarea {
    width: 90%;
  }

  footer {
    height: 85px;
  }

  footer .footer-title {
    font-size: 1.1rem;
  }
  footer .footer-copyright {
    font-size: 0.9rem;
  }
}

/* --------------- BREAKPOINT 992 px --------------- */
@media (min-width: 992px) {
  .banner {
    background-image: url("../img/striscia.gif");
  }
}
/* ----------------------------------- END RESPONSIVE ----------------------------------- */
