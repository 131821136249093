*, :before, :after {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: Urbanist, sans-serif;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.container {
  width: 90%;
  margin: 0 auto;
}

.highlight {
  font-weight: 700;
}

nav {
  z-index: 999;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: .8em 5%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px #0000003d;
}

.left-nav {
  align-items: center;
  gap: .4em;
  display: flex;
}

.left-nav .logo-image {
  width: 34px;
  height: 34px;
}

.left-nav .logo-image img {
  width: 100%;
  height: 100%;
}

.left-nav .logo-text {
  color: #202020;
  font-size: 1.3rem;
  font-weight: 700;
}

.right-nav {
  align-items: center;
  display: flex;
}

.right-nav ul {
  display: none;
}

.burger-menu {
  display: block;
}

.right-nav ul li {
  margin-left: 2em;
  font-size: 1.1rem;
}

.right-nav ul li a {
  color: #202020;
  font-weight: 300;
}

.right-nav .burger-menu {
  width: 26px;
}

.right-nav .burger-menu:hover {
  cursor: pointer;
}

.burger-menu-window {
  background-color: #fff;
  border-radius: 8px;
  width: auto;
  height: auto;
  font-weight: 700;
  display: none;
  position: absolute;
  top: 80px;
  right: 5%;
  box-shadow: 0 19px 38px #0000004d, 0 15px 12px #00000038;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    right: -100%;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    opacity: 0;
    right: -100%;
  }
}

.burger-menu-window ul {
  flex-direction: column;
  gap: 1em;
  padding: 1em 1em 2em 2em;
  display: flex;
}

.burger-menu-window ul li {
  text-align: right;
  color: #fff;
  text-align: center;
  background-color: #202020;
  border-radius: 3px;
  width: 100%;
  padding: .3em 1em;
  font-size: 1.3rem;
  font-weight: 300;
}

.banner {
  color: #000;
  background-image: url("onde.484b7430.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  height: calc(100vh - 75px);
  margin-top: 75px;
  display: flex;
}

.date {
  font-size: 1rem;
}

.banner .main-title h1 {
  margin: .3em 0;
  font-size: 1.8rem;
}

.banner .main-subtitle p {
  font-size: 1.2rem;
  line-height: 1.5;
}

main .container {
  color: #202020;
  max-width: 1000px;
  margin-bottom: 3em;
}

#series {
  padding-top: 3em;
}

div.currently-subtitle, div.form-subtitle {
  margin-top: .3em;
  font-size: 1.1rem;
  line-height: 1.5;
}

div.currently-subtitle {
  font-weight: 300;
}

.posts-container {
  flex-direction: column;
  margin: 1em 0;
  display: flex;
}

.posts-container .post-image {
  border-radius: 3px;
  width: 100%;
  height: 180px;
}

.posts-container .post-image img {
  border-radius: 3px;
}

.post {
  margin: 1.5em 0;
}

.post .date {
  color: #202020;
  padding: .8em 0 .6em;
}

.post-title h2 {
  color: #202020;
  font-size: 1.3rem;
}

.post-description {
  color: #202020;
  padding: .6em 0;
}

.post-description p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.show-more-posts {
  text-align: center;
  margin-bottom: 3em;
}

.show-more-posts button {
  color: #fff;
  background-color: #202020;
  border: none;
  border-radius: 3px;
  padding: 1em 2em;
  font-size: .9rem;
  font-weight: 700;
  transition: all .3s;
}

.show-more-posts button:hover {
  cursor: pointer;
}

#music-playlist {
  background-color: #121212;
  padding: 3em 0 1em;
}

#music-playlist .currently-title, #music-playlist .currently-subtitle {
  color: #fff;
}

.music-container {
  color: #fff;
  flex-direction: column;
  margin: 1em 0;
  display: flex;
}

.song {
  align-items: center;
  gap: 1em;
  margin: .8em 0;
  display: flex;
}

.song .song-number {
  font-weight: 700;
}

.song .song-image {
  border-radius: 3px;
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
}

.song .song-image img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.song .song-info {
  flex-direction: column;
  align-self: flex-start;
  gap: .2em;
  display: flex;
}

.song-info .song-name {
  font-size: 1.1rem;
  font-weight: 700;
}

.song-info .song-artist {
  font-size: 1rem;
}

#nba-highlights {
  padding: 3em 0 0;
}

.single-highlight {
  margin-bottom: 2em;
}

.highlight-title {
  color: #202020;
  margin: 2em 0 .3em;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
}

.highlight-video {
  border-radius: 3px;
  width: 100%;
  height: 240px;
}

.highlight-video video {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.highlights {
  flex-direction: column;
  margin: 1em 0;
  display: flex;
}

#about-me {
  background-color: #202020;
  padding: 3em 0 1em;
}

.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 2em;
}

.avatar img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

#about-me .description p {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.5;
}

.second-paragraph {
  margin: 1em 0;
}

.cat-typing {
  border-radius: 5px;
  max-width: 400px;
  max-height: 400px;
}

.cat-typing img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.socials {
  align-items: center;
  gap: 1em;
  margin: 2em 0;
  display: flex;
}

.social {
  width: 40px;
  height: 40px;
}

.social a:hover {
  cursor: pointer;
}

.social img {
  width: 100%;
  height: 100%;
}

.contact-form {
  padding: 3em 0 0;
}

form {
  margin: 2em 0 1em;
}

.form-content {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.inputs {
  flex-direction: column;
  gap: 1em;
  width: 100%;
  display: flex;
}

.inputs input, textarea, button#btn-submit {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  outline: none;
  padding: .8em 1em;
  font-family: inherit;
  font-size: 1rem;
}

.textarea, textarea {
  width: 100%;
}

.success {
  opacity: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  max-height: 80px;
  margin-bottom: .5em;
  display: flex;
}

button#btn-submit {
  color: #fff;
  text-align: center;
  background-color: #202020;
  width: auto;
  margin: 0 auto;
  padding: .8em 2em;
  font-weight: 700;
}

button#btn-submit:hover {
  cursor: pointer;
}

footer {
  color: #fff;
  text-align: center;
  background: #202020;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  display: flex;
}

footer .footer-title {
  margin-bottom: .3em;
  font-size: 1rem;
  font-weight: 400;
}

footer .footer-copyright {
  font-size: .85rem;
}

.back-to-top {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: none;
  position: fixed;
  bottom: 1em;
  right: 1em;
  box-shadow: 0 5px 15px #00000059;
}

.back-to-top:hover {
  cursor: pointer;
}

.back-to-top img {
  width: 100%;
  height: 100%;
}

@keyframes arrow-in {
  0% {
    right: -100%;
  }

  100% {
    right: 1em;
  }
}

@keyframes arrow-out {
  0% {
    right: 1em;
  }

  100% {
    right: -100%;
  }
}

@media (width >= 480px) {
  nav {
    padding: 1.2em 5%;
  }

  .banner .main-title h1 {
    font-size: 2.2rem;
  }

  .banner .main-subtitle p {
    font-size: 1.2rem;
  }

  .banner-content .date {
    font-size: .9rem;
  }
}

@media (width >= 600px) {
  .posts-container {
    flex-flow: wrap;
    justify-content: space-between;
    gap: .25em;
  }

  .post {
    width: calc(50% - .5em);
  }
}

@media (width >= 768px) {
  .left-nav {
    gap: .8em;
  }

  .left-nav .logo-image {
    width: 46px;
    height: 46px;
  }

  .left-nav .logo-text {
    font-size: 1.6rem;
  }

  .right-nav ul {
    display: flex;
  }

  .burger-menu, .burger-menu-window {
    display: none;
  }

  .banner-content, .about-me-content {
    max-width: 900px;
  }

  .banner .main-subtitle p {
    font-size: 1.3rem;
  }

  .posts-container {
    gap: .5em;
  }

  .highlights {
    gap: 1em;
  }

  .post {
    width: calc(33.3333% - 1em);
  }

  .highlights {
    flex-flow: wrap;
    justify-content: space-between;
  }

  .single-highlight {
    width: calc(50% - .5em);
  }

  .post-title h2 {
    font-size: 1.3rem;
  }

  .inputs {
    flex-direction: row;
  }

  .inputs input {
    width: calc(45% - .5em);
  }

  textarea {
    width: 90%;
  }

  footer {
    height: 85px;
  }

  footer .footer-title {
    font-size: 1.1rem;
  }

  footer .footer-copyright {
    font-size: .9rem;
  }
}

@media (width >= 992px) {
  .banner {
    background-image: url("striscia.aa41f6b2.gif");
  }
}

/*# sourceMappingURL=index.b78e123b.css.map */
